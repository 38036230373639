import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import {Link, graphql } from "gatsby"
import { Styled, Flex, Box, Grid, Image, Message } from 'theme-ui'
import '../styles/books.css'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

function Books({ data }) {
  const Bold = ({ children }) => <span className="bold">{children}</span>
  const Text = ({ children }) => <p className="align-center">{children}</p>
  
const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode:  {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return(
        <Box className="intro-text"> 
        <Text>{children}</Text>
        </Box>
      )
        },
    },
  }

  return (
    <Layout>
    <SEO title="books" />
    <h1 >Books</h1>
    <Message style={{backgroundColor:"#838383"}}>
      Our books are sale by donation
      You decide the price. Buy by click on the book.
      You will receive a code and you can use the code on our sister site.
      reedstreetpress.com
    </Message>
    <br></br>

    {data.allWordpressWpBooks.edges.map(({ node }) => (
      <Grid  key={'/books/' + node.slug} gap={1} columns={[2, '1fr 2fr']} className="books-display">
        <Box>
        <Styled.a as={Link} to={'/books/' + node.slug}>
        <Img key={node.featured_media.localFile.childImageSharp.resolutions.src} fluid={node.featured_media.localFile.childImageSharp.fluid} 
        style={{padding:2}}
        />
        </Styled.a>
        </Box>
        <Box>
        <Styled.a as={Link} to={'/books/' + node.slug}>
        <h2 className="title-header" dangerouslySetInnerHTML={{__html: node.title}} />
        </Styled.a>
        {/* <h6>Category:{node.categories[0].name.charAt(0).toUpperCase() + node.categories[0].name.slice(1)}</h6> */}
        <div className="content" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
        </Box>
        <br></br>
      </Grid>

    ))}
        {data.allContentfulSuzzie.edges.map(({ node }) => (
          <Grid  key={'/books/' + node.slug} gap={1} columns={[2, '1fr 2fr']} className="books-display">
            <Box>
              <Styled.a as={Link} to={'/books/' + node.slug}>
              <Image src={node.thumbnail.fixed.src} 
              style={{padding:2}}
            />
        </Styled.a>
            </Box>
            <Box>
              <Styled.a as={Link} to={'/books/' + node.slug}>
              <h2 className="title-header" dangerouslySetInnerHTML={{__html: node.title}} />
              </Styled.a>
              {/* render text component in contentful */}
              {documentToReactComponents(JSON.parse(node.excerpt.raw),options)}
              </Box>
              <br></br>
            </Grid>
        ))}
  </Layout>
  )
}

export default Books

export const query = graphql`
  query {
    allWordpressWpBooks(sort: { fields: [date] }) {
        edges {
          node {
            title
            excerpt
            date
            slug
            categories {
              id
              name
            }
            featured_media {
              localFile {
                  childImageSharp {
                    fluid(maxWidth:250, quality:100){
                      ...GatsbyImageSharpFluid
                      ...GatsbyImageSharpFluidLimitPresentationSize
                    }
                    resolutions(width:350, height: 500) {
                      ...GatsbyImageSharpResolutions_withWebp_tracedSVG
                    }
                    fixed(width: 250) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                } 
            }
          }
        }
      }
      allContentfulSuzzie {
        edges {
          node {
            title
            slug
            excerpt {
              raw
            }
            content {
              raw
            }

            thumbnail {
              contentful_id
              fixed(width: 250)   {
                src
              }
            }
          }
        }
      }

  }
`